.html {
    font-size: 16px;
    min-width: 360px;
}

.html, .body {
    width: 100%;
    height: auto;
}

.mainContainer {
    height: 100%;
    display: flex;
    min-height: 100vh;
    flex-direction: column;
}

.main {
    flex-grow: 1;
    display: flex;
    min-height: 100%;
    flex-direction: column;
}